/*
 * @Description: 返佣结算列表接口
 */

import {get,postJSON } from "@/utils/request"

export function pageQuery(data) {
    return get('/system/discount/list', data)
}
export function findById(data) {
    return get('/system/discount/' + data)
}
// 提现
export function withdrawDiscount(data) {
    return postJSON('/system/discount/withdraw',data)
}