<template>
  <div class="page-warp gray-page min-page">
    <div class="row page-model-title">事件流程</div>
    <van-steps :active="activeA" class="pages-steps">
        <van-step v-for="(item,inx) in Alldata.billTimeStreamList" :key="inx" :name="inx">{{item.statusName}}</van-step>
    </van-steps>
    <div class="row page-model-title">基本信息</div>
    <van-cell-group inset>
        <van-cell title="申报单号: " :value="Alldata.billNo" />
        <van-cell title="返佣状态: " :value="cms[Alldata.commissionStatus]" />
        <van-cell title="纳税所属期: " :value="Alldata.declareMonth" />
        <van-cell title="增值税纳税金额: " :value="Alldata.addedValueAmount" />
        <van-cell title="附加税纳税金额: " :value="Alldata.additionalAmount" />
        <van-cell title="所得税纳税金额: " :value="Alldata.corpoamountIncomeAmount" />
        <van-cell title="个税纳税金额: " :value="Alldata.individualIncomeAmount" />
        <van-cell title="纳税总金额: " :value="Alldata.totalAmount" />
        <van-cell title="返佣比例: " :value="Alldata.commissionRateStr" />
        <van-cell title="返佣金额（应付）: " :value="Alldata.commissionAmount" />
        <van-cell title="返佣金额（实付）: " :value="Alldata.commissionAmountActually" v-if="Alldata.commissionStatus == 4" />
        <van-cell title="开户行: " :value="Alldata.accountName" />
        <van-cell title="开户账号: " :value="Alldata.companyAccount" />
    </van-cell-group>
    <!-- <div class="pro-title">{{Alldata.companyName}}</div>
    <van-cell-group class="mt-10" inset>
      <van-cell title="申报单号: " :value="Alldata.billNo" />
      <van-cell title="返佣状态: " :value="cms[Alldata.commissionStatus]" />
      <van-cell title="纳税所属期: " :value="Alldata.declareMonth" />
      <van-cell title="增值税纳税金额: " :value="Alldata.addedValueAmount" />
      <van-cell title="附加税纳税金额: " :value="Alldata.additionalAmount" />
      <van-cell title="所得税纳税金额: " :value="Alldata.corpoamountIncomeAmount" />
      <van-cell title="个税纳税金额: " :value="Alldata.individualIncomeAmount" />
      <van-cell title="纳税总金额: " :value="Alldata.totalAmount" />
      <van-cell title="返佣比例: " :value="Alldata.commissionRateStr" />
      <van-cell title="返佣金额（应付）: " :value="Alldata.commissionAmount" />
      <van-cell title="返佣金额（实付）: " :value="Alldata.commissionAmountActually" v-if="Alldata.commissionStatus == 4" />
      <van-cell title="开户行: " :value="Alldata.accountName" />
      <van-cell title="开户账号: " :value="Alldata.companyAccount" />
    </van-cell-group>
    <van-steps direction="vertical" :active="activeA">
        <van-step v-for="(item,inx) in Alldata.billTimeStreamList" :key="inx" :name="inx">
            <h3>{{item.statusName}}</h3>
            <p>{{item.operationTime}}</p>
        </van-step>
    </van-steps> -->
  </div>
</template>
<script>
import { findById } from "@/api/discount";
export default {
    data() {
        return {
            findId: "",
            activeA:'',
            Alldata: {},
            cms:{
                1: '申报中',
                2: '待提现',
                3: '支付中',
                4: '已支付'
            },
            dimsL:{
                1: '申请提交',
                2: '财务审核驳回',
                3: '财务审核',
                4: '财政审核驳回',
                5: '财政审核',
                6: '拨款到账',
                7: '奖励提现',
                8: '奖励支付',
                9: '返佣提现',
                10: '返佣支付',
                11:'支付完成',
                12:'作废'
            }
        };
    },
    computed: {},
    methods: {
        //   通过id查询订单信息
        async findAll() {
            let res = await findById(this.findId);
            this.Alldata = res.data;
            this.Alldata.billTimeStreamList.map((item,index)=>{
                if(item.operationTime){
                    this.activeA = index;
                }
                item.statusName = this.dimsL[item.operationNode];
            })
        },
    },
    created() {
        this.findId = this.$route.query.id;
        this.findAll();
    },
    mounted() {},
};
</script>
<style lang="less" scoped>
.page-model-title {
    text-align: left; line-height: 40px; font-size: 14px; color: #080808;
    box-sizing: border-box; padding:0px 10px;
}
</style>